* ::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}

* ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

* ::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}

.bar-light ::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.01);
}

.bar-light ::-webkit-scrollbar {
  background-color: rgba(0, 0, 0, 0.05);
}

.bar-light ::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.25)
}

.bar-dark ::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
}

.bar-dark ::-webkit-scrollbar {
  background-color: rgba(0, 0, 0, 0.3);
}

.bar-dark ::-webkit-scrollbar-thumb {
  background-color: #4A5568
}

