@media (max-width: 520px) {
  .leaflet-top.leaflet-right{
    top: 60px;
  }
}

@media (max-width: 720px) {
  .leaflet-legend-custom{
    bottom: 125px !important;
    left: 10px !important;
  }
}

.leaflet-container{
  font-family: "Roboto", "Helvetica Neue" !important;
}

.leaflet-bar, .leaflet-container{
  border: none !important
}

.header-control{
  margin: 8px;
}

.leaflet-control{
  user-select: none;
}

.header-control, .leaflet-bar, .container-minimap, .leaflet-popup-notice{
  box-shadow: 0px 0px 6px rgb(0 0 0 / 30%) !important;
  border-radius: 8px !important;
}

.leaflet-touch .leaflet-bar a:first-child,
.leaflet-control-measure-toggle{
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
}
.leaflet-touch .leaflet-bar a:last-child,
.leaflet-control-measure-toggle{
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

.leaflet-top.leaflet-left{
  top: 52px;
}

.leaflet-bottom.leaflet-right{
  bottom: 15px;
}

.leaflet-control-attribution{
  position: absolute !important;
  bottom: -15px;
  right: 0px;
  white-space: nowrap;
}

.leaflet-bar a, .leaflet-bar a:hover{
  cursor: pointer;
}

.leaflet-grab {
  cursor: auto !important;
}

.leaflet-dragging .leaflet-grab {
  cursor: move !important;
}

.leaflet-control.leaflet-bar.button-enabled{
  opacity: 0.5;
}

.leaflet-container.crosshair-cursor-enabled {cursor:crosshair;}

.leaflet-measure-resultpopup .tasks a{
  color: var(--chakra-colors-blue-400) !important
}

.leaflet-popup-content{
  margin: 2px 10px !important;
}

.leaflet-popup-content-wrapper, .leaflet-popup-tip{
  background-color: rgba(0,0,0, 0.65) !important;
  color: white !important;
  -webkit-print-color-adjust: exact;
}
